<i18n>
{
  "en": {
    "browser_title_default": "Help",
    "browser_title_whatisvrack": "What is V-RACK?"
  },
  "ja": {
    "browser_title_default": "Help",
    "browser_title_whatisvrack": "What is V-RACK?"
  }
}
</i18n>

<template>
<main>
  <vue-headful v-bind:title="dynamicTitle" />
  <component :is="templateName" />
</main>
</template>

<script>
/* eslint max-len: 0 */

// generic component for /help/ that will load a separate .vue file via a `?page=` param
// to add a new page, do the following:
// 1. import component
// 2. add component to the `components` object
// 3. set browser title in the i18n section above

import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import help from '@/components/help/help.vue';
import whatisvrack from '@/components/help/whatisvrack.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    help,
    whatisvrack,
  },
  data() {
    return {
      dynamicTitle: '',
      templateName: null,
    };
  },
  mounted() {
    this.loadTemplate(this.$route.query.page);
  },
  created() {
    // set browser title
    const browserTitle = `browser_title_${(this.$route.query.page || 'default')}`;
    this.dynamicTitle = `${this.$t(browserTitle)} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  methods: {
    loadTemplate(page) {
      if (!page) {
        // Default template or error handling
        this.templateName = 'help';
      } else {
        // Load templates based on query parameter
        this.templateName = page;
      }
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
</style>
