<i18n>
{
  "en": {
  },
  "ja": {
  }
}
</i18n>

<template>
<main>
  <div>
  </div>
</main>
</template>

<script>
/* eslint max-len: 0 */


export default {
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
</style>
