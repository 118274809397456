<i18n>
{
  "en": {
    "header_title": "What is V-RACK",
    "header_body": "It is a free online storage service where D2Pass members can save videos and favorite videos.<br />Equipped with many useful functions, such as creating your own collections.",
    "highlight01_title": "ー V-RACK Functions ー",
    "highlight02_title": "ー V-RACK Services ー",
    "item01_title": "Huge storage for movies",
    "item01_description": "If you save it to V-RACK, you don't need to take up your hard drive space.",
    "item02_title": "Save Videos Forever ",
    "item02_description": "Videos in V-RACK are permanently stored on the server. No need to worry about data corruption or loss.",
    "item03_title": "Free Video Management",
    "item03_description": "You can create your own collections like creating folders by genre or actress type.",
    "item04_title": "Watch From Anywhere",
    "item04_description": "All you need is the internet and a device. Accessible anytime, anywhere.",
    "item05_title": "Enjoy Indefinitely",
    "item05_description": "You can continue to watch the saved videos for free even after canceling your membership on the partner site.",
    "item06_title": "Reduce hassle and time",
    "item06_description": "Eliminate the hassle and time of downloading huge video files.",
    "highlight_note": "Notes",
    "highlight_note01": "D2Pass account is required to access the V-RACK library",
    "highlight_note02": "Internet connection is required to watch videos",
    "highlight_note03": "Up to 3 videos can be saved per day",
    "highlight_note04": " ",
    "highlight_note05": "Saved videos may be deleted due to various circumstances",
    "howto01_title": "How to save to V-RACK",
    "howto01_description": "Click the 'Save to V-RACK' button below the video player to save.",
    "howto02_title": "Enjoy indefinitely",
    "howto02_description": "You can access videos saved in V-RACK by logging in to the site where you registered as a member and clicking the blue video icon on the screen.",
    "howto03_title": "Save videos in folders",
    "howto03a_description": "&#10102; Press the '+ Add' button under the video list on the left to create your own video list",
    "howto03b_description": "&#10103; Mouse over the video image and select the folder you wish to sort into.",
    "sites_heading": "V-RACK is also available at",
    "sites_note": "More services are being added to the list!"
  },
  "ja": {
    "header_title": "V-RACKとは",
    "header_body": "D2Pass会員が購入した動画やお気に入りの動画を保存しておける無料オンラインストレージです。<br />あなた好みのプレイリストの作成など、便利な機能を多数搭載しています。",
    "highlight01_title": "ー V-RACKの便利な機能 ー",
    "highlight02_title": "ー V-RACKのサービス ー",
    "item01_title": "膨大なデータの保存先に",
    "item01_description": "V-RACKに保存すればダウンロードの容量の確保は不要です。",
    "item02_title": "動画を永久保存",
    "item02_description": "V-RACK内の動画はサーバー上で永久保存。データの破損や紛失の心配はありません。",
    "item03_title": "動画の管理が自由自在",
    "item03_description": "ジャンルや女優タイプでのフォルダ分けなどあなただけのコレクションが作れます。",
    "item04_title": "どこからでも視聴可能",
    "item04_description": "必要なのはインターネットとデバイスだけ。いつでもどこからでもアクセス可能。",
    "item05_title": "期限を気にせず楽しめる",
    "item05_description": "保存した動画は提携サイトのメンバーシップを退会後も引き続き無料で視聴できます。",
    "item06_title": "手間と時間を削減",
    "item06_description": "莫大な量の動画データをダウンロードする手間と時間をゼロに。",
    "highlight_note": "注意事項",
    "highlight_note01": "V-RACK内のライブラリへのアクセスにはD2Passのアカウントが必要です。",
    "highlight_note02": "動画の視聴にはインターネット接続が必要です。",
    "highlight_note03": "24時間で最大3本保存可能です。",
    "highlight_note04": "旧スマホ会員様はご利用できません。",
    "highlight_note05": "諸事情により保存した動画が削除されることがあります。",
    "howto01_title": "V-RACKへの保存方法",
    "howto01_description": "作品ページ右画面下に表示された「V-RACKに保存する」ボタンをクリックして保存。",
    "howto02_title": "過去に保存した動画を観る",
    "howto02_description": "会員登録をしたサイトにログインし、画面上の青色のビデオアイコンをクリックするとV-RACKに保存された動画にアクセスできます。",
    "howto03_title": "動画をフォルダー分け保存",
    "howto03a_description": "&#10102; 左の動画リスト下の「＋追加」ボタンを押して、独自の動画リストを作成",
    "howto03b_description": "&#10103; 動画の画像にマウスオーバー、仕分けしたいフォルダを選択",
    "sites_heading": "V-RACKが利用可能なその他のサービス",
    "sites_note": "利用可能サービスは今後も続々と追加予定です。"
  }
}
</i18n>

<template>
<div id="wiv">
  <div class="contents">
    <div class="wiv-header">
      <img class="header-image" :src="`${this.imgPath}/wiv-top-${locale}.png`" alt="">
      <div class="header-tagline">
        <div class="tagline-title">{{ $t('header_title') }}</div>
        <p class="tagline-body" v-html="$t('header_body')"></p>
      </div>
    </div>

    <div class="wiv-body">
      <div class="wiv-highlight">
        <div class="highlight-title">{{ $t('highlight01_title') }}</div>
        <div class="grid-container">
          <div class="highlight-item">
            <div class="item-title">{{ $t('item01_title') }}</div>
            <div class="item-description">{{ $t('item01_description') }}</div>
            <div class="item-figure">
              <img :src="`${this.imgPath}/wiv-highlight-01.png`" alt="">
            </div>
          </div>
          <div class="highlight-item">
            <div class="item-title">{{ $t('item02_title') }}</div>
            <div class="item-description">{{ $t('item02_description') }}</div>
            <div class="item-figure">
              <img :src="`${this.imgPath}/wiv-highlight-02.png`" alt="">
            </div>
          </div>
          <div class="highlight-item">
            <div class="item-title">{{ $t('item03_title') }}</div>
            <div class="item-description">{{ $t('item03_description') }}</div>
            <div class="item-figure">
              <img :src="`${this.imgPath}/wiv-highlight-03.png`" alt="">
            </div>
          </div>
        </div>
        <div class="highlight-title">{{ $t('highlight02_title') }}</div>
        <div class="grid-container">
          <div class="highlight-item">
            <div class="item-title">{{ $t('item04_title') }}</div>
            <div class="item-description">{{ $t('item04_description') }}</div>
            <div class="item-figure">
              <img :src="`${this.imgPath}/wiv-highlight-04.png`" alt="">
            </div>
          </div>
          <div class="highlight-item">
            <div class="item-title">{{ $t('item05_title') }}</div>
            <div class="item-description">{{ $t('item05_description') }}</div>
            <div class="item-figure">
              <img :src="`${this.imgPath}/wiv-highlight-05.png`" alt="">
            </div>
          </div>
          <div class="highlight-item">
            <div class="item-title">{{ $t('item06_title') }}</div>
            <div class="item-description">{{ $t('item06_description') }}</div>
            <div class="item-figure">
              <img :src="`${this.imgPath}/wiv-highlight-06.png`" alt="">
            </div>
          </div>
        </div>
        <div class="highlight-note">
          {{ $t('highlight_note') }}
          <ul>
            <li>{{ $t('highlight_note01') }}</li>
            <li>{{ $t('highlight_note02') }}</li>
            <li>{{ $t('highlight_note03') }}</li>
            <li v-if="locale ==='ja'">{{ $t('highlight_note04') }}</li>
            <li>{{ $t('highlight_note05') }}</li>
          </ul>
        </div>
      </div>

      <div class="wiv-howto">
        <div class="howto-header">
          <img :src="`${this.imgPath}/wiv-howto-top-${locale}.jpg`" alt="">
        </div>
        <div class="grid-container">
          <div class="howto-section">
            <div class="howto-section-title">{{ $t('howto01_title') }}</div>
            <div class="howto-instruction">
              <div class="instruction-item">
                <div class="item-figure">
                  <img :src="`${this.imgPath}/wiv-howto-01-${locale}.png`" alt="">
                </div>
                <div class="item-description">{{ $t('howto01_description') }}</div>
              </div>
            </div>
          </div>
          <div class="howto-section">
            <div class="howto-section-title">{{ $t('howto02_title') }}</div>
            <div class="howto-instruction">
              <div class="instruction-item">
                <div class="item-figure">
                  <img :src="`${this.imgPath}/wiv-howto-02.png`" alt="">
                </div>
                <div class="item-description">{{ $t('howto02_description') }}</div>
              </div>
            </div>
          </div>
          <div class="howto-section">
            <div class="howto-section-title">{{ $t('howto03_title') }}</div>
            <div class="howto-instruction">
              <div class="instruction-item">
                <div class="item-figure">
                  <img :src="`${this.imgPath}/wiv-howto-03a-${locale}.png`" alt="">
                </div>
                <div class="item-description" v-html="$t('howto03a_description')"></div>
              </div>
              <div class="instruction-item">
                <div class="item-figure">
                  <img :src="`${this.imgPath}/wiv-howto-03b-${locale}.png`" alt="">
                </div>
                <div class="item-description" v-html="$t('howto03b_description')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wiv-sites">
        <div class="section-heading">{{ $t('sites_heading') }}</div>
        <div class="sites-list">
          <a class="sites-list-item" href="https://bit.ly/4dcOn27" target="_blank" rel="noopener">
            <img :src="`${this.imgPath}/logo-heyzo.svg`" alt="">
          </a>
          <a class="sites-list-item" href="https://bit.ly/3AczCxz" target="_blank" rel="noopener">
            <img :src="`${this.imgPath}/logo-heydouga.svg`" alt="">
          </a>
        </div>
        <p class="sites-note">{{ $t('sites_note') }}</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */

export default {
  data() {
    return {
    };
  },
  created() {
    // non-reactive constants
    this.imgPath = '/img/pages/help/whatisvrack';
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
#wiv {
  font-size: 16px;
  line-height: 1.75;

  .section-heading {
    font-size: 1.75rem;
    font-weight: bold;
    margin: 3rem 0 1.5rem;
    text-align: center;
  }

  img {
    display: block;
    margin: auto;
    width: 100%;
  }

  .grid-container {
    display: grid;
    gap: 1rem;
  }

  //// Header ////

  .wiv-header {
    background: #222;

    .header-image {
      width: 100%;
    }

    .header-tagline {
      color: #fff;
      padding: 0 2rem 2rem;
      text-align: center;
    }

    .tagline-title {
      font-size: 2.5rem;
      font-weight: bold;
    }

    .tagline-body {
      margin: 0;
    }
  }

  //// Highlight ////

  .wiv-highlight {
    padding: 1rem 0;

    .grid-container {
      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .item-title {
      color: #e63950;
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1.5;
    }

    .item-description {
      margin: 1rem 0 1.25rem;
    }

    .item-figure {
      img {
        max-width: 150px;
      }
    }
  }

  .highlight-title {
    background: #7d7d7d;
    color: #fff;
    font-size: 1.75rem;
    font-weight: bold;
    margin: 16px 0;
    padding: .5rem;
    text-align: center;
  }

  .highlight-item {
    align-items: center;
    background: #d2d2d2;
    border-radius: .5rem;
    display: flex;
    flex-flow: column nowrap;
    padding: 1.5rem;
  }

  .highlight-note {
    margin: 2rem auto;
    max-width: 768px;

    ul {
      margin-left: 1.4em;
    }

    li {
      list-style-type: disc;
      margin: .5em 0;
    }
  }

  //// How To ////

  .howto-instruction {
    background: #fafafa;
    border: 1px solid #ccc;
    border-radius: .5rem;
    // box-shadow: 0 8px 12px -4px rgb(0 0 0 / .05), 0 4px 8px -4px rgb(0 0 0 / .05);

    .instruction-item {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      width: 100%;

      &:not(:first-child) {
        border-top: 1px solid #ccc;
      }

      @media screen and (min-width: 768px) {
        flex-direction: row;
        padding: 1.5rem;
      }
    }

    .item-figure {
      flex-shrink: 0;

      @media screen and (min-width: 768px) {
        max-width: 320px;
        width: 50%;
      }

      img {
        width: auto;
      }
    }

    .item-description {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  .howto-header {
    img {
      width: 100%;
    }
  }

  .howto-section-title {
    color: #e63950;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5;
    margin: 1em 0;
    text-align: center;
    // text-shadow: 1px 1px 1px rgb(0 0 0 / .7);
  }

  //// Other Sites ////
  .wiv-sites {
    .sites-list {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;
      justify-content: center;
    }

    .sites-list-item {
      border-radius: .5rem;
      max-width: 224px;
      transition: .15s;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: #f8f8f8;
        }
      }
    }

    .sites-note {
      text-align: center;
    }
  }
}
</style>
